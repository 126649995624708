
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="户号" prop="customerNo">
          <el-input v-model="formModel.customerNo" placeholder="请输入户号" style="width:300px"></el-input>
        </el-form-item>
        <!-- <el-form-item label="房间号" prop="roomId">
          <el-select
            v-model="formModel.roomId"
            filterable
            remote
            placeholder
            :remote-method="queryRoomInfo"
            style="width:300px"
          >
            <el-option
              v-for="roomInfo in roomInfoListFilter"
              :key="roomInfo.id"
              :label="roomInfo.name"
              :value="roomInfo.id"
            ></el-option>
          </el-select>
          <el-button icon="el-icon-search" circle size="small" @click="innerVisible=true;"></el-button>
        </el-form-item> -->
        <el-form-item label="房间号" prop="roomName">
          <el-input
            placeholder="请选择"
            v-model="formModel.roomName"
            readonly="readonly"
            style="width:300px"
            @click.native="isShowSelect = !isShowSelect">
          </el-input>
        <el-tree  v-if="isShowSelect"
          :props="props"
          :load="loadNode"
          lazy
          @node-click="handleNodeClick">
        </el-tree>
        </el-form-item>
        <el-form-item label="电表号" prop="meterId">
          <el-select
            v-model="formModel.meterId"
            filterable
            remote
            placeholder
            :remote-method="queryMeterInfo"
            style="width:300px"
          >
            <el-option
              v-for="meterInfo in meterInfoListFilter"
              :key="meterInfo.id"
              :label="meterInfo.name"
              :value="meterInfo.id"
            ></el-option>
          </el-select>
          <el-button icon="el-icon-search" circle size="small" @click="innerVisible=true;"></el-button>
          <el-input
          type="hidden"
            v-model="formModel.roomId"
            icon="caret-bottom" 
            readonly="readonly">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import electricClientInfoApi from "@/api/base/electricClientInfo";
import roomInfoApi from "@/api/base/roomInfo";
import electricMeterInfoApi from "@/api/base/electricMeterInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        customerNo: [
          { required: true, message: "户号不能为空", trigger: "blur" }
        ],
        roomName: [
          { required: true, message: "房间号不能为空", trigger: "blur" }
        ],
        meterId: [
          { required: true, message: "电表号不能为空", trigger: "blur" }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      roomInfoList: [],
      meterInfoList:[],
      props: {
        id:'id',
        label: 'name',
        children: 'children'
      },
      isShowSelect: false,
      count: 1
    };
  },
  computed: {
    roomInfoListFilter() {
      var self = this;

      return self.roomInfoList.filter(roomInfo => {
        return roomInfo.id;
      });
    },
    meterInfoListFilter() {
      var self = this;

      return self.meterInfoList.filter(meterInfo => {
        return meterInfo.id;
      });
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;
            if (id == null || id.length == 0) {
              return electricClientInfoApi.add(self.formModel);
            } else {
              return electricClientInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    queryRoomInfo(keywords) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("type", 3);
      formData.append("excludeId", this.formModel.id);
      formData.append("limit", 10);

      return roomInfoApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.roomInfoList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    queryMeterInfo(keywords) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("excludeId", this.formModel.id);
      formData.append("limit", 10);

      return electricMeterInfoApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.meterInfoList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    handleNodeClick(data,Node) {
      if (Node.parent.level === 2) {
        this.formModel.roomName = data.name;
        this.formModel.roomId = data.id;
        // 关闭选择器
        this.isShowSelect = false;
      }
    },
    loadNode(node, resolve) {
        if (node.level === 0) {
          return roomInfoApi.queryTree(formData).then(response => {
            var jsonData = response.data;
            if (jsonData.result) {
              return resolve(jsonData.data);
            } else {
              this.$message.error(jsonData.message + "");
            }
          });
        }else{
          var formData = new FormData();
          var parentId = node.data.id;
          formData.append("parentId", parentId);
          return roomInfoApi.queryTree(formData).then(response => {
            var jsonData = response.data;
            if (jsonData.result) {
              return resolve(jsonData.data);
            } else {
              this.$message.error(jsonData.message + "");
            }
          });
        }
      }
  },
  async mounted() {
    var self = this;
    this.queryRoomInfo("");
    this.queryMeterInfo("");
    (function() {
      if (self.businessKey.length == 0) {
        return electricClientInfoApi.create();
      } else {
        return electricClientInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>